import React from 'react';
import { Link } from 'gatsby';

const RestoreUsername = () => {
  return (
    <>
      <section className="restoreUsername">
        <div className="restoreUsername_wrapper-outer">
          <div className="restoreUsername_wrapper-inner">
            <h1>Don’t worry</h1>
            <p>We have e-mailed your username.</p>
            <Link to="/">
              <button className="restoreUsername_backToLogin btn">Back to login</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default RestoreUsername;
