import React from 'react';

import Layout from '../components/Layout';
import RestoreUsername from '../components/RestoreUsername';

const UsermnameRestore = () => (
  <Layout>
    <RestoreUsername />
  </Layout>
);

export default UsermnameRestore;
